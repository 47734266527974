import { HTMLAttributes } from 'react';

import styled, { StyledComponentBase } from 'styled-components';

interface IScModal
  extends StyledComponentBase<'div', any, HTMLAttributes<HTMLDivElement>> {
  Content?: any;
  ScrollableContent?: any;
  TopNavigation?: any;
  CloseBtn?: any;
  Background?: any;
}

const ScModal: IScModal = styled.div`
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100dvw;
  padding: 16px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: 0.25s ease-in-out;
  overflow-y: auto;
  &.open {
    pointer-events: auto;
    opacity: 1;
  }
`;

ScModal.Content = styled.div`
  margin: auto;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 100;
  gap: 4px;
  position: relative;
  transform: scale(0.4);
  border: 1px solid ${({ theme }) => theme.colors.border.system};
  border-radius: ${({ theme }) => theme.radius.modal};
  background: ${({ theme }) => theme.colors.background.modal};
  transition: 0.25s ease-in-out;
  &.open {
    transform: scale(1);
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    flex-grow: 1;
  }
`;

ScModal.ScrollableContent = styled.div`
  padding: ${({ theme }) => theme.spacing['modal-y']}
    ${({ theme }) => theme.spacing['modal-x']};
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    padding: 8px 12px 20px;
    overflow: auto;
  }
`;

ScModal.TopNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

ScModal.CloseBtn = styled.button`
  align-self: flex-end;
  display: block;
  ${({ theme }) => theme.spacing['modal-x']};
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  right: 12px;
  top: 12px;
  svg {
    height: 36px;
    width: 36px;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    position: relative;
    align-self: flex-end;
    svg {
      height: 24px;
      width: 24px;
    }
  }
`;

ScModal.Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(27, 28, 33, 0.6);
  opacity: 0;
  pointer-events: none;
  transition: 0.25s ease-in-out;
  z-index: 99;
  &.open {
    opacity: 1;
    pointer-events: auto;
  }
`;

export default ScModal;

import styled from 'styled-components/macro';

export const Frame = styled.div`
  position: fixed;
  height: calc(100dvh - 62px);
  inset: 62px 0 0;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 16px 0 0;
  gap: 4px;
  background: ${({ theme }) => theme.colors.background.foreground};
  z-index: 99;
  transform: translateY(100%);
  opacity: 0.2;
  transition: 0.25s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: none;
  &.open {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
  }
  &.isVisibleBottomTabs {
    height: calc(-117px + 100dvh);
  }
`;

export const TopNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 0 16px;
  &:empty {
    display: none;
  }
`;

export const BackBtn = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  font: ${({ theme }) => theme.typography['paragraph-medium']};
  color: ${({ theme }) => theme.colors.button.text.linkLabel};
  padding: 0;
`;

export const CloseBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  background: none;
  border: none;
  padding: 0;
  margin-left: auto;
`;

export const ScrollableContent = styled.div`
  overflow: hidden auto;
  padding: 0 16px 16px;
`;

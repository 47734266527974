import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor4X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24.91c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12Z"
        fill="#93C5FD"
        opacity={0.8}
      />
      <path
        d="m8.616 12.518 1.248-1.92h1.848l-2.16 3.036c.48.6.916 1.184 1.308 1.752.392.568.704 1.076.936 1.524h-1.92a55.29 55.29 0 0 0-.24-.444 8.38 8.38 0 0 0-.324-.564c-.12-.2-.248-.396-.384-.588a6.31 6.31 0 0 0-.384-.54c-.12.152-.244.32-.372.504a13.3 13.3 0 0 0-.348.564l-.336.552c-.104.184-.196.356-.276.516H5.388c.096-.2.228-.436.396-.708a21.82 21.82 0 0 1 1.164-1.716c.216-.296.428-.572.636-.828-.36-.504-.72-1.012-1.08-1.524-.36-.52-.716-1.032-1.068-1.536h1.932l1.248 1.92Zm7.124-1.74c-.312.408-.644.852-.996 1.332-.352.48-.648.972-.888 1.476h1.884v-2.808Zm1.752-2.184v4.992h.888v1.464h-.888v1.86H15.74v-1.86h-3.588v-1.308c.176-.352.396-.748.66-1.188a22.826 22.826 0 0 1 1.908-2.748c.36-.448.72-.852 1.08-1.212h1.692Z"
        fill="#2D3344"
      />
    </svg>
  );
};
export default SvgNotVerifyFactor4X;

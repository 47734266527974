import * as React from 'react';
import { SVGProps } from 'react';

const SvgHistoryExchangeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={29}
      height={27}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.739a1.2 1.2 0 0 0-1.41-1.181L2.99 4.986A1.2 1.2 0 0 0 2 6.167V20a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-3V3.739ZM16 7V4.694L4 6.837V7h12ZM4 9v10h16V9H4Zm11 4h3v2h-3v-2Z"
        fill="#F8FAFC"
      />
      <rect
        x={15.5}
        y={13.5}
        width={13}
        height={13}
        rx={6.5}
        fill="#2D3344"
        stroke="#2D3344"
      />
      <path
        d="M24 22v-2l2.5 2.5L24 25v-2h-6v-1h6Zm-4-7v2h6v1h-6v2l-2.5-2.5L20 15Z"
        fill="#94A3B8"
      />
    </svg>
  );
};
export default SvgHistoryExchangeIcon;

import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCurrentRoute } from 'react-navi';

import { useTheme } from 'hooks/theme';

import { Icon } from 'components/UI/Icon';

import * as Styled from './styled';

const BottomTabs = () => {
  const { t } = useTranslation('common');
  const {
    colors: { icons },
  } = useTheme();
  const { url } = useCurrentRoute();

  return (
    <Styled.TabsContainer>
      <Styled.TabLink
        className={cx('', {
          active: url.pathname === '/',
        })}
        href="/"
      >
        <Icon name="ExchangeIcon" size="small" fill={icons.primary} />
        <span>{t('bottomTabs.exchange')}</span>
      </Styled.TabLink>
      <Styled.TabLink
        href="/wallet"
        className={cx('', {
          active: url.pathname === '/wallet',
        })}
      >
        <Icon name="WalletSoonIcon" size="small" fill={icons.primary} />
        <span>{t('bottomTabs.wallet')}</span>
      </Styled.TabLink>
      <Styled.TabLink
        className={cx('', {
          active: url.pathname === '/crypto-check',
        })}
        href="/crypto-check"
      >
        <Icon name="CryptoCheckIcon" size="small" fill={icons.primary} />
        <span>{t('bottomTabs.cryptoCheck')}</span>
      </Styled.TabLink>
      <Styled.TabLink
        className={cx('', {
          active: url.pathname === '/api',
        })}
        href="/settingsApi"
      >
        <Icon name="ApiIcon" size="small" fill={icons.primary} />
        <span>API</span>
      </Styled.TabLink>
    </Styled.TabsContainer>
  );
};

export default BottomTabs;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgPercentIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.5 21a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Zm-11-11a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Zm12.571-6.485 1.414 1.414L4.93 20.485l-1.414-1.414L19.07 3.515h.001Z" />
    </svg>
  );
};
export default SvgPercentIcon;

import * as React from 'react';
import { SVGProps } from 'react';

const SvgSilverStatus = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M33.124.381c-2.816 2.79-12.14 11.16-24.435 13.448-1.065.208-1.34.398-1.494 1.525-.858 6.48-2.919 35.663 25.706 52.369.704.364 1.425.364 2.164 0 16.124-9.41 22.512-22.77 24.864-33.81L34 0c-.292 0-.584.121-.876.381Z"
          fill="#E1EBF0"
        />
        <path
          d="M7.315 29.702a45.5 45.5 0 0 0 .738 4.211h51.893l-3.21-4.21H7.314Z"
          fill="#87A0AE"
        />
        <path
          d="M34 33.913h25.947c1.82-8.56 1.219-15.735.84-18.577-.154-1.109-.446-1.317-1.476-1.507C47.033 11.559 37.691 3.17 34.876.38 34.584.121 34.292 0 34 0v33.913Z"
          fill="#FFD04E"
        />
        <path
          d="M51.846 16.782c0-.598-.495-1.082-1.106-1.082-.61 0-1.106.484-1.106 1.082 0 .231.075.446.202.622l-1.501 1.47a1.114 1.114 0 0 0-.477-.186v-3.786a1.09 1.09 0 0 0 .947-1.07c0-.598-.495-1.082-1.106-1.082-.61 0-1.105.484-1.105 1.082 0 .525.381.962.887 1.06v3.805a1.088 1.088 0 0 0-.888 1.06c0 .232.076.446.202.622l-1.513 1.482a1.11 1.11 0 0 0-.476-.195V17.85c.527-.083.93-.528.93-1.067 0-.598-.495-1.082-1.106-1.082-.61 0-1.105.484-1.105 1.082 0 .53.39.97.904 1.063v3.825a1.088 1.088 0 0 0-.904 1.063c0 .598.495 1.082 1.105 1.082s1.106-.484 1.106-1.082c0-.225-.07-.434-.191-.608l1.517-1.485c.123.086.265.148.42.178v3.804a1.088 1.088 0 0 0-.889 1.06c0 .598.495 1.083 1.106 1.083.61 0 1.106-.485 1.106-1.082a1.09 1.09 0 0 0-.947-1.07v-3.786a1.09 1.09 0 0 0 .947-1.07 1.06 1.06 0 0 0-.203-.623l1.5-1.47c.132.092.284.155.45.183v3.821c-.521.088-.918.53-.918 1.065 0 .598.495 1.082 1.106 1.082.61 0 1.105-.484 1.105-1.082 0-.534-.396-.977-.917-1.065v-3.821c.52-.088.918-.53.918-1.066Zm2.076 1.721a1.15 1.15 0 0 0-1.161 1.139c0 .628.52 1.138 1.16 1.138a1.15 1.15 0 0 0 1.162-1.139 1.15 1.15 0 0 0-1.161-1.138Zm-12.386 0a1.15 1.15 0 0 0-1.161 1.139c0 .628.52 1.138 1.161 1.138a1.15 1.15 0 0 0 1.161-1.139 1.15 1.15 0 0 0-1.16-1.138Z"
          fill="#7A5E0F"
        />
        <path
          d="M34 33.913h25.947c.309-1.438.549-2.842.738-4.21H34v4.21Z"
          fill="#AA8215"
        />
        <path
          d="M8.053 33.913c2.353 11.039 8.74 24.4 24.865 33.81.36.173.721.277 1.082.277V33.913H8.053Z"
          fill="#FFD04E"
        />
        <path
          d="M28.47 42.282c0-.598-.494-1.082-1.105-1.082-.61 0-1.106.484-1.106 1.081 0 .232.075.447.202.623l-1.501 1.47a1.114 1.114 0 0 0-.477-.186v-3.786a1.09 1.09 0 0 0 .947-1.07c0-.598-.495-1.082-1.106-1.082-.61 0-1.106.484-1.106 1.082 0 .525.382.962.889 1.06v3.805a1.088 1.088 0 0 0-.889 1.06c0 .232.076.446.203.623l-1.514 1.48a1.11 1.11 0 0 0-.476-.194V43.35c.527-.083.93-.528.93-1.067 0-.598-.495-1.082-1.106-1.082-.61 0-1.105.484-1.105 1.081 0 .53.39.971.904 1.064v3.825a1.088 1.088 0 0 0-.904 1.063c0 .598.495 1.082 1.105 1.082s1.106-.484 1.106-1.082c0-.225-.07-.434-.191-.608l1.517-1.484c.123.085.265.147.42.177v3.804a1.088 1.088 0 0 0-.889 1.06c0 .598.495 1.083 1.106 1.083.61 0 1.106-.485 1.106-1.082a1.09 1.09 0 0 0-.947-1.07v-3.786a1.09 1.09 0 0 0 .947-1.07 1.06 1.06 0 0 0-.203-.623l1.5-1.47c.132.092.284.155.45.183v3.821c-.521.088-.918.53-.918 1.065 0 .598.495 1.082 1.106 1.082.61 0 1.106-.484 1.106-1.082a1.09 1.09 0 0 0-.918-1.065v-3.821c.52-.088.918-.53.918-1.066Zm2.077 1.721a1.15 1.15 0 0 0-1.162 1.139c0 .628.52 1.138 1.162 1.138a1.15 1.15 0 0 0 1.16-1.139 1.15 1.15 0 0 0-1.16-1.138Zm-12.386 0A1.15 1.15 0 0 0 17 45.142c0 .628.52 1.138 1.161 1.138a1.15 1.15 0 0 0 1.161-1.139 1.15 1.15 0 0 0-1.16-1.138Z"
          fill="#7A5E0F"
        />
        <path
          d="M32.9 63.512C18.667 55.61 11.678 44.917 8.329 35.162c2.559 10.795 9.067 23.515 24.59 32.56.36.174.721.278 1.082.278v-4.21a2.3 2.3 0 0 1-1.1-.278Z"
          fill="#AA8215"
        />
        <path
          d="M59.534 35.733c-3.417 9.6-10.423 20.033-24.366 27.78-.395.19-.79.276-1.168.276v4.212c.36 0 .721-.087 1.082-.278 15.248-8.89 21.79-21.332 24.452-31.99Z"
          fill="#87A0AE"
        />
      </g>
    </svg>
  );
};
export default SvgSilverStatus;

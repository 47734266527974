import styled from 'styled-components/macro';

export const ScHeader = styled.header`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: solid 1px ${({ theme }) => theme.colors.border.system};
  background: ${({ theme }) => theme.colors.background.header};
  z-index: 2;
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    height: 62px;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    background: ${({ theme }) => theme.colors.background.foreground};
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-height: 68px;
    max-width: 1440px;
    padding: 0 20px;
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      padding: 0 16px;
    }
  }
  .wrap {
    height: 68px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      margin: 0 auto;
    }
  }
  .logo {
    width: auto;
    min-width: 195px;
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      margin: 0 auto;
    }
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      padding: 0;
      min-width: initial;
    }
    a {
      display: inline-block;
      @media (max-width: ${({ theme }) => theme.mobileSize}) {
        display: none;
      }
      &.mobile {
        display: none;
        @media (max-width: ${({ theme }) => theme.mobileSize}) {
          display: block;
        }
      }
    }
  }

  nav {
    display: flex;
  }
`;

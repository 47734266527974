import * as React from 'react';
import { SVGProps } from 'react';

const SvgApiIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={303}
      height={217}
      viewBox="0 0 303 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M286.908 45.419a4.922 4.922 0 0 0-1.511-1.492 5.035 5.035 0 0 0-2.012-.727l-21.464-2.897-7.71-19.415a4.893 4.893 0 0 0-1.203-1.764 4.966 4.966 0 0 0-1.849-1.094 71.297 71.297 0 0 0-26.363-3.418 5.166 5.166 0 0 0-3.678 1.842l-13.932 16.722-21.601-2.743a5.165 5.165 0 0 0-3.954 1.157 71.459 71.459 0 0 0-16.928 20.525 4.983 4.983 0 0 0-.608 2.06c-.05.72.058 1.439.318 2.108l7.558 19.495-13.738 16.765c-.46.56-.793 1.21-.978 1.905a4.938 4.938 0 0 0-.091 2.122 64.854 64.854 0 0 0 9.444 24.034 4.92 4.92 0 0 0 1.511 1.491 5.039 5.039 0 0 0 2.012.728l21.464 2.897 7.712 19.42a4.886 4.886 0 0 0 1.203 1.764 4.97 4.97 0 0 0 1.849 1.094 71.315 71.315 0 0 0 26.363 3.419 5.16 5.16 0 0 0 2.031-.541 5.145 5.145 0 0 0 1.647-1.302l13.939-16.757 21.601 2.743a5.095 5.095 0 0 0 2.528-.328 5.228 5.228 0 0 0 1.421-.841 71.557 71.557 0 0 0 16.932-20.514c.351-.637.559-1.34.609-2.059a4.888 4.888 0 0 0-.318-2.109l-7.558-19.495 13.738-16.764c.46-.561.793-1.21.977-1.906a4.907 4.907 0 0 0 .091-2.121 64.85 64.85 0 0 0-9.452-24.005Zm-51.056 60.709a25.723 25.723 0 0 1-14.645 1.267 24.931 24.931 0 0 1-12.772-6.957 24.375 24.375 0 0 1-6.594-12.835 24.73 24.73 0 0 1 1.805-14.39 25.56 25.56 0 0 1 9.597-11.09 25.85 25.85 0 0 1 14.154-4.056c5 .052 9.852 1.565 13.94 4.347a24.481 24.481 0 0 1 9.027 11.284c2.411 6.135 2.236 12.999-.486 19.08-2.721 6.082-7.767 10.884-14.026 13.35Z"
        fill="#364356"
      />
      <ellipse
        cx={154.854}
        cy={200.485}
        rx={135.383}
        ry={6.84}
        fill="url(#a)"
      />
      <g clipPath="url(#b)">
        <rect
          x={109.596}
          y={27.271}
          width={64.468}
          height={46.087}
          rx={4.338}
          fill="#1E293B"
        />
        <path
          d="M109.596 31.61a4.338 4.338 0 0 1 4.338-4.338h55.793a4.337 4.337 0 0 1 4.337 4.337v6.506h-64.468V31.61Z"
          fill="#1E293B"
        />
        <rect
          opacity={0.2}
          x={165.396}
          y={30.525}
          width={4.334}
          height={4.338}
          rx={2.167}
          fill="#FC680A"
        />
        <rect
          opacity={0.2}
          x={157.27}
          y={30.525}
          width={4.334}
          height={4.338}
          rx={2.167}
          fill="#FC680A"
        />
        <rect
          opacity={0.2}
          x={149.144}
          y={30.525}
          width={4.334}
          height={4.338}
          rx={2.167}
          fill="#FC680A"
        />
        <path
          opacity={0.2}
          fill="#1D4ED8"
          d="M114.064 42.744h9.751v2.169h-9.751z"
        />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M113.93 46.791h9.751v2.169h-9.751z"
        />
        <path fill="#FEAD05" d="M113.93 53.299h9.751v2.169h-9.751z" />
        <path
          opacity={0.2}
          fill="#1D4ED8"
          d="M160.655 42.744h9.751v2.169h-9.751z"
        />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M160.521 61.973h9.751v2.169h-9.751z"
        />
        <path fill="#334155" d="M126.932 42.453h31.963v2.169h-31.963z" />
        <path fill="#1E293B" d="M126.932 61.973h31.963v2.169h-31.963z" />
        <path
          opacity={0.2}
          fill="#1D4ED8"
          d="M126.932 46.791h16.794v2.169h-16.794z"
        />
        <path fill="#1E293B" d="M126.932 53.299h20.586v2.169h-20.586z" />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M126.932 57.635h13.002v2.169h-13.002z"
        />
        <path fill="#FEAD05" d="M126.932 66.311h13.002v2.169h-13.002z" />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M145.894 46.791h16.794v2.169h-16.794z"
        />
        <path
          fill="#FEAD05"
          d="M148.602 53.299h21.128v2.169h-21.128zM142.102 57.635h13.002v2.169h-13.002z"
        />
        <path
          fill="#ECF4F5"
          fillOpacity={0.16}
          d="M142.101 66.311h13.002v2.169h-13.002z"
        />
        <path fill="#1E293B" d="M157.27 57.635h13.002v2.169H157.27z" />
      </g>
      <rect
        x={109.097}
        y={26.771}
        width={65.465}
        height={47.084}
        rx={4.838}
        stroke="#1E293B"
        strokeWidth={0.997}
      />
      <g filter="url(#c)">
        <path fill="url(#d)" d="M110.24 49.223h63.844v4.56H110.24z" />
      </g>
      <path
        d="M86.006 127.789a3.251 3.251 0 0 0-.998-.985 3.328 3.328 0 0 0-1.33-.481l-14.184-1.916-5.097-12.827a3.233 3.233 0 0 0-.794-1.165 3.288 3.288 0 0 0-1.222-.723 47.146 47.146 0 0 0-17.422-2.261 3.417 3.417 0 0 0-2.43 1.217l-9.205 11.045-14.275-1.814a3.418 3.418 0 0 0-2.613.764 47.192 47.192 0 0 0-11.183 13.558 3.295 3.295 0 0 0-.402 1.361c-.033.475.038.95.21 1.393l4.996 12.879-9.076 11.074a3.34 3.34 0 0 0-.646 1.259 3.253 3.253 0 0 0-.06 1.402 42.84 42.84 0 0 0 6.243 15.878c.256.396.597.732.999.985.401.253.855.417 1.33.481l14.183 1.916 5.099 12.831c.175.442.446.839.794 1.165.349.326.766.573 1.223.723a47.125 47.125 0 0 0 17.421 2.261 3.417 3.417 0 0 0 2.43-1.217l9.21-11.069 14.274 1.814a3.456 3.456 0 0 0 2.61-.771 47.27 47.27 0 0 0 11.187-13.551c.231-.421.368-.885.402-1.36a3.228 3.228 0 0 0-.21-1.393l-4.997-12.88 9.076-11.074c.304-.37.524-.799.646-1.258.122-.459.142-.937.06-1.402a42.843 42.843 0 0 0-6.249-15.859Zm-33.732 40.102a17.01 17.01 0 0 1-9.677.836 16.491 16.491 0 0 1-8.44-4.598 16.108 16.108 0 0 1-4.36-8.48 16.33 16.33 0 0 1 1.192-9.505 16.883 16.883 0 0 1 6.34-7.327 17.093 17.093 0 0 1 9.353-2.678 16.687 16.687 0 0 1 9.212 2.873 16.186 16.186 0 0 1 5.967 7.456c1.593 4.053 1.479 8.588-.32 12.605-1.797 4.018-5.131 7.189-9.267 8.818Z"
        fill="url(#e)"
      />
      <rect
        x={65.076}
        y={52.636}
        width={174.776}
        height={116.615}
        rx={5.583}
        fill="#2D3344"
        stroke="#475569"
        strokeWidth={1.241}
      />
      <rect
        x={71.894}
        y={59.459}
        width={161.142}
        height={102.969}
        rx={1.241}
        fill="#272D3D"
      />
      <path
        d="M71.894 60.7c0-.686.555-1.241 1.24-1.241h158.661c.685 0 1.241.555 1.241 1.24v13.647H71.894V60.7Z"
        fill="#1E293B"
      />
      <path
        d="M127.673 169.871h48.962l2.479 24.192h-53.92l2.479-24.192Z"
        fill="#28303F"
      />
      <path
        d="M127.673 169.871h48.962l.62 7.444h-50.202l.62-7.444Z"
        fill="#1E293B"
      />
      <path
        d="M114.658 189.72a4.963 4.963 0 0 1 4.963-4.962h65.688a4.963 4.963 0 0 1 4.962 4.962v4.342h-75.613v-4.342Z"
        fill="#343B4E"
      />
      <rect
        opacity={0.5}
        x={220.728}
        y={64.555}
        width={4.958}
        height={4.962}
        rx={2.479}
        fill="#FFD04E"
      />
      <rect
        opacity={0.5}
        x={211.058}
        y={64.555}
        width={4.958}
        height={4.962}
        rx={2.479}
        fill="#FFD04E"
      />
      <rect
        opacity={0.5}
        x={202.032}
        y={64.555}
        width={4.958}
        height={4.962}
        rx={2.479}
        fill="#FFD04E"
      />
      <path
        d="M183.375 110.423a2.264 2.264 0 0 0-1.102-1.532l-8.453-4.824-.034-9.54a2.264 2.264 0 0 0-.802-1.725 31.702 31.702 0 0 0-10.406-5.865 2.266 2.266 0 0 0-1.83.168l-8.527 4.772-8.536-4.78a2.269 2.269 0 0 0-1.833-.171 31.715 31.715 0 0 0-10.395 5.888 2.274 2.274 0 0 0-.802 1.722l-.042 9.548-8.453 4.824a2.264 2.264 0 0 0-1.103 1.532 30.27 30.27 0 0 0 0 11.792 2.267 2.267 0 0 0 1.103 1.532l8.453 4.824.034 9.542a2.264 2.264 0 0 0 .802 1.725 31.683 31.683 0 0 0 10.406 5.865 2.253 2.253 0 0 0 1.83-.167l8.536-4.787 8.535 4.781a2.287 2.287 0 0 0 1.834.165 31.754 31.754 0 0 0 10.394-5.882 2.28 2.28 0 0 0 .802-1.722l.042-9.548 8.454-4.824a2.274 2.274 0 0 0 1.102-1.532c.769-3.89.766-7.893-.009-11.781Zm-31.154 17.248a11.326 11.326 0 0 1-10.473-7.006 11.364 11.364 0 0 1 2.457-12.369 11.32 11.32 0 0 1 12.353-2.46 11.341 11.341 0 0 1 6.998 10.486c0 3.01-1.194 5.897-3.32 8.025a11.326 11.326 0 0 1-8.015 3.324Z"
        fill="#FEAD05"
      />
      <path
        d="M210.569 91.8a.947.947 0 0 0-.463-.645l-3.55-2.029-.014-4.013a.968.968 0 0 0-.337-.726 13.314 13.314 0 0 0-4.37-2.467.95.95 0 0 0-.769.07l-3.582 2.008-3.584-2.01a.956.956 0 0 0-.77-.073 13.31 13.31 0 0 0-4.366 2.477.966.966 0 0 0-.337.725l-.018 4.016-3.55 2.03a.963.963 0 0 0-.463.644 12.742 12.742 0 0 0 0 4.96.963.963 0 0 0 .463.645l3.55 2.03.015 4.013c0 .139.03.275.089.4a.944.944 0 0 0 .247.326 13.29 13.29 0 0 0 4.371 2.467.948.948 0 0 0 .769-.07l3.584-2.014 3.585 2.012a.962.962 0 0 0 .77.069 13.324 13.324 0 0 0 4.366-2.475.965.965 0 0 0 .337-.724l.018-4.017 3.55-2.029a.963.963 0 0 0 .463-.644c.323-1.636.322-3.32-.004-4.956Zm-13.085 7.256a4.77 4.77 0 0 1-4.398-2.947 4.78 4.78 0 0 1 1.032-5.203 4.756 4.756 0 0 1 5.188-1.036c.87.362 1.614.974 2.137 1.759a4.785 4.785 0 0 1-.592 6.028 4.755 4.755 0 0 1-3.367 1.399Z"
        fill="#64748B"
      />
      <path
        opacity={0.2}
        d="M201.975 139.852a.631.631 0 0 0-.302-.419l-2.312-1.319-.009-2.608a.613.613 0 0 0-.219-.472 8.695 8.695 0 0 0-2.846-1.604.613.613 0 0 0-.501.046l-2.332 1.305-2.334-1.307a.61.61 0 0 0-.501-.047 8.677 8.677 0 0 0-2.843 1.61.616.616 0 0 0-.219.471l-.012 2.611-2.312 1.319a.623.623 0 0 0-.301.419 8.251 8.251 0 0 0 0 3.224.616.616 0 0 0 .301.419l2.312 1.319.009 2.609a.628.628 0 0 0 .22.472 8.68 8.68 0 0 0 2.845 1.604.633.633 0 0 0 .501-.046l2.334-1.309 2.335 1.307a.61.61 0 0 0 .501.045 8.682 8.682 0 0 0 2.843-1.608.628.628 0 0 0 .219-.471l.012-2.611 2.312-1.319a.623.623 0 0 0 .301-.419 8.274 8.274 0 0 0-.002-3.221Zm-8.521 4.716a3.098 3.098 0 0 1-2.864-1.915 3.108 3.108 0 0 1 2.26-4.232 3.094 3.094 0 0 1 3.182 1.32 3.102 3.102 0 0 1-2.578 4.827Z"
        fill="#575C69"
      />
      <path
        d="M277.307 59.989a.692.692 0 0 0-.485-.305l-2.951-.398-1.06-2.67a.673.673 0 0 0-.42-.393 9.803 9.803 0 0 0-3.625-.47.708.708 0 0 0-.506.254l-1.915 2.299-2.97-.377a.711.711 0 0 0-.544.158 9.834 9.834 0 0 0-2.328 2.823.691.691 0 0 0-.04.573l1.04 2.68-1.889 2.306a.694.694 0 0 0-.147.553 8.915 8.915 0 0 0 1.298 3.305.68.68 0 0 0 .485.305l2.951.399 1.06 2.67a.673.673 0 0 0 .42.393 9.807 9.807 0 0 0 3.625.47.711.711 0 0 0 .506-.253l1.916-2.305 2.97.377a.728.728 0 0 0 .544-.16 9.865 9.865 0 0 0 2.328-2.82.676.676 0 0 0 .04-.574l-1.04-2.68 1.889-2.306a.693.693 0 0 0 .147-.553 8.914 8.914 0 0 0-1.299-3.301Zm-7.021 8.348a3.535 3.535 0 0 1-2.013.174 3.429 3.429 0 0 1-1.756-.957 3.35 3.35 0 0 1-.907-1.765 3.403 3.403 0 0 1 .248-1.978 3.51 3.51 0 0 1 1.32-1.525 3.552 3.552 0 0 1 1.946-.558 3.473 3.473 0 0 1 1.917.598 3.365 3.365 0 0 1 1.174 4.175 3.527 3.527 0 0 1-1.929 1.836Z"
        fill="#2D3344"
      />
      <path
        d="M115.703 134.638a.958.958 0 0 0-.463-.645l-3.551-2.029-.014-4.013a.953.953 0 0 0-.337-.726 13.332 13.332 0 0 0-4.37-2.467.949.949 0 0 0-.769.071l-3.581 2.007-3.585-2.011a.966.966 0 0 0-.377-.118.968.968 0 0 0-.393.046 13.32 13.32 0 0 0-4.366 2.477.952.952 0 0 0-.337.725l-.017 4.016-3.55 2.029a.959.959 0 0 0-.464.645 12.747 12.747 0 0 0 0 4.961.955.955 0 0 0 .463.644l3.55 2.029.015 4.014a.954.954 0 0 0 .337.726 13.293 13.293 0 0 0 4.37 2.467.948.948 0 0 0 .769-.07l3.585-2.014 3.585 2.011a.966.966 0 0 0 .77.07 13.338 13.338 0 0 0 4.365-2.475.952.952 0 0 0 .337-.724l.018-4.017 3.55-2.029a.948.948 0 0 0 .463-.644 12.756 12.756 0 0 0-.003-4.956Zm-13.085 7.256c-.942 0-1.862-.28-2.645-.805a4.785 4.785 0 0 1-.721-7.346 4.755 4.755 0 0 1 7.325.724 4.783 4.783 0 0 1-.593 6.028 4.752 4.752 0 0 1-3.366 1.399Z"
        fill="#374151"
      />
      <ellipse
        cx={152.465}
        cy={116.528}
        rx={14.875}
        ry={14.887}
        fill="#FEAD05"
      />
      <path
        d="m134.694 122.728 6.55-13.994h2.878l6.551 13.994h-3.653l-1.21-2.838h-6.253l-1.211 2.838h-3.652Zm7.959-10.183-1.905 4.546h3.851l-1.906-4.546h-.04ZM151.52 122.728v-13.994h6.729c1.588 0 2.812.411 3.672 1.231.874.807 1.31 1.925 1.31 3.355 0 1.429-.436 2.554-1.31 3.374-.86.807-2.084 1.211-3.672 1.211h-3.077v4.823h-3.652Zm3.652-7.622h2.442c1.429 0 2.144-.595 2.144-1.786s-.715-1.787-2.144-1.787h-2.442v3.573ZM164.934 122.728v-13.994h3.652v13.994h-3.652Z"
        fill="#fff"
      />
      <rect
        x={18.901}
        y={46.08}
        width={74.894}
        height={53.865}
        rx={5.535}
        stroke="#475569"
        strokeWidth={1.14}
      />
      <rect
        x={19.472}
        y={46.65}
        width={73.754}
        height={52.725}
        rx={4.962}
        fill="#2D3344"
      />
      <path
        d="M19.472 51.613a4.962 4.962 0 0 1 4.962-4.963h63.829a4.962 4.962 0 0 1 4.962 4.963v7.443H19.472v-7.443Z"
        fill="#1E293B"
      />
      <rect
        opacity={0.5}
        x={83.309}
        y={50.373}
        width={4.958}
        height={4.962}
        rx={2.479}
        fill="#FFD04E"
      />
      <rect
        opacity={0.5}
        x={74.012}
        y={50.373}
        width={4.958}
        height={4.962}
        rx={2.479}
        fill="#FFD04E"
      />
      <rect
        opacity={0.5}
        x={64.716}
        y={50.373}
        width={4.958}
        height={4.962}
        rx={2.479}
        fill="#FFD04E"
      />
      <path
        d="M72.489 75.145c-.238-6.153-5.339-11.069-11.48-11.069a11.49 11.49 0 0 0-6.05 1.723 11.528 11.528 0 0 0-5.456 9.766 1.073 1.073 0 0 1-.971 1.083 1.045 1.045 0 0 1-1.12-1.047 13.604 13.604 0 0 1 .698-4.312.525.525 0 0 0-.62-.678 8.371 8.371 0 0 0-4.57 2.972 8.406 8.406 0 0 0-1.78 5.16c0 4.609 3.888 8.382 8.493 8.382H61a11.489 11.489 0 0 0 8.286-3.542 11.54 11.54 0 0 0 3.203-8.438Zm-7.613 5.387-4.181 4.191a1.044 1.044 0 0 1-1.48 0l-4.18-4.19a1.05 1.05 0 0 1 .74-1.79c.276 0 .543.11.739.307l2.396 2.403v-7.948a1.05 1.05 0 0 1 1.045-1.047A1.044 1.044 0 0 1 61 73.504v7.948l2.397-2.403a1.045 1.045 0 0 1 1.785.741c0 .278-.11.545-.306.741Z"
        fill="#FEAD05"
      />
      <g clipPath="url(#f)">
        <rect
          x={216.132}
          y={101.373}
          width={73.754}
          height={52.725}
          rx={4.965}
          fill="#2D3344"
        />
        <rect
          x={216.133}
          y={101.373}
          width={73.754}
          height={52.725}
          rx={4.962}
          fill="#2D3344"
        />
        <path
          d="M216.133 106.335a4.963 4.963 0 0 1 4.962-4.962h63.829a4.962 4.962 0 0 1 4.962 4.962v7.444h-73.753v-7.444Z"
          fill="#1E293B"
        />
        <rect
          opacity={0.5}
          x={280.039}
          y={105.17}
          width={4.958}
          height={4.962}
          rx={2.479}
          fill="#FFD04E"
        />
        <rect
          opacity={0.5}
          x={270.369}
          y={105.17}
          width={4.958}
          height={4.962}
          rx={2.479}
          fill="#FFD04E"
        />
        <rect
          opacity={0.5}
          x={261.343}
          y={105.17}
          width={4.958}
          height={4.962}
          rx={2.479}
          fill="#FFD04E"
        />
        <path fill="#FEAD05" d="M221.091 118.742h11.156v2.481h-11.156z" />
        <path fill="#CBD5E1" d="M221.091 123.703h11.156v2.481h-11.156z" />
        <path
          fill="#FEAD05"
          d="M221.091 131.148h11.156v2.481h-11.156zM274.392 118.742h11.156v2.481h-11.156z"
        />
        <path fill="#CBD5E1" d="M274.392 141.072h11.156v2.481h-11.156z" />
        <path
          fill="#1E293B"
          d="M235.965 118.742h36.567v2.481h-36.567zM235.965 141.072h36.567v2.481h-36.567z"
        />
        <path fill="#FEAD05" d="M235.965 123.703h19.213v2.481h-19.213z" />
        <path fill="#1E293B" d="M235.965 131.148h23.552v2.481h-23.552z" />
        <path fill="#CBD5E1" d="M235.965 136.109h14.875v2.481h-14.875z" />
        <path fill="#FEAD05" d="M235.965 146.035h14.875v2.481h-14.875z" />
        <path fill="#CBD5E1" d="M257.658 123.703h19.213v2.481h-19.213z" />
        <path
          fill="#FEAD05"
          d="M260.757 131.148h24.171v2.481h-24.171zM253.32 136.109h14.875v2.481H253.32z"
        />
        <path fill="#CBD5E1" d="M253.32 146.033h14.875v2.481H253.32z" />
        <path fill="#1E293B" d="M270.673 136.109h14.875v2.481h-14.875z" />
      </g>
      <rect
        x={215.562}
        y={100.803}
        width={74.894}
        height={53.865}
        rx={5.535}
        stroke="#475569"
        strokeWidth={1.14}
      />
      <defs>
        <linearGradient
          id="d"
          x1={141.952}
          y1={41.959}
          x2={142.187}
          y2={53.646}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D3344" stopOpacity={0} />
          <stop offset={1} stopColor="#2D3344" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={-37.714}
          y1={110.756}
          x2={46.49}
          y2={201.265}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3C4A5D" />
          <stop offset={1} stopColor="#2D3344" />
        </linearGradient>
        <clipPath id="b">
          <rect
            x={109.596}
            y={27.27}
            width={64.468}
            height={46.087}
            rx={4.34}
            fill="#fff"
          />
        </clipPath>
        <clipPath id="f">
          <rect
            x={216.132}
            y={101.373}
            width={73.754}
            height={52.725}
            rx={4.965}
            fill="#fff"
          />
        </clipPath>
        <radialGradient
          id="a"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 -7.73617 153.108 0 154.724 193.597)"
        >
          <stop stopColor="#1E293B" />
          <stop offset={1} stopColor="#2D3344" />
        </radialGradient>
        <filter
          id="c"
          x={106.25}
          y={45.232}
          width={71.824}
          height={12.541}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={1.995}
            result="effect1_foregroundBlur_6511_94102"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgApiIllustration;

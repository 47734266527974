export const ENV = {
  IS_STAGING: process.env.REACT_APP_ENV !== 'staging',

  PUBLIC_URL: process.env.PUBLIC_URL,
  BUILD_VERSION: process.env.BUILD_VERSION,
  APP_TITLE: process.env.REACT_APP_TITLE,
  APP_THEME: process.env.REACT_APP_THEME,
  APP_VERSION: process.env.APP_VERSION,
  APP_RELEASE: process.env.REACT_APP_RELEASE,
  APP_BUILD_DATE: process.env.APP_BUILD_DATE,
  APP_SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
  CRYSTAL_PM_ID: process.env.REACT_APP_CRYSTAL_PM_ID,
  BESTCHANGE_URL: process.env.REACT_APP_BESTCHANGE_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  CHATRA_ID: process.env.REACT_APP_CHATRA_ID,
};

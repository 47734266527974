import { Replay } from '@sentry/browser';
import * as Sentry from '@sentry/react';

import { hideParamsFromUrl } from 'helpers/base';

const links = [
  'refund/****',
  'recalculate/****',
  'bids/confirmation/****',
  'bids/confirmation/****/redirect',
  'settingsApi/bids/****/status',
  'settingsApi/bids/****/confirmation',
  'settingsApi/bids/refund/****',
  'settingsApi/bids/recalculate/****',
];

const marker = '****';

if (process.env.REACT_APP_SENTRY_DSN != null) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release:
      process.env.REACT_APP_THEME + '_' + process.env.REACT_APP_SHORT_SHA,
    environment: process.env.REACT_APP_ENV + '_' + process.env.REACT_APP_THEME,
    integrations: [new Replay()],
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_RATE
      ? parseInt(process.env.REACT_APP_SENTRY_TRACES_RATE)
      : undefined,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5,
    beforeSend(e, hint) {
      if (hint.originalException === 'Timeout') return null;

      if (e.request != null && e.request.url != null) {
        e.request.url = hideParamsFromUrl(e.request.url, marker, links);
      }

      if (e.breadcrumbs != null) {
        e.breadcrumbs = e.breadcrumbs.map((breadcrumb) => {
          if (breadcrumb.category === 'navigation' && breadcrumb.data != null) {
            return {
              ...breadcrumb,
              data: {
                from: hideParamsFromUrl(breadcrumb.data.from, marker, links),
                to: hideParamsFromUrl(breadcrumb.data.to, marker, links),
              },
            };
          } else if (breadcrumb.category === 'xhr' && breadcrumb.data != null) {
            return {
              ...breadcrumb,
              data: {
                ...breadcrumb.data,
                url: hideParamsFromUrl(breadcrumb.data.url, marker, links),
              },
            };
          }

          return breadcrumb;
        });
      }

      if (e.exception != null && e.exception.values != null) {
        e.exception.values = e.exception.values.map((value) => {
          if (value.stacktrace != null && value.stacktrace.frames != null) {
            return {
              ...value,
              stacktrace: {
                ...value.stacktrace,
                frames: value.stacktrace.frames.map((frame) => {
                  if (frame.filename == null) return frame;

                  return {
                    ...frame,
                    filename: hideParamsFromUrl(frame.filename, marker, links),
                  };
                }),
              },
            };
          }

          return value;
        });
      }

      return e;
    },
  });
}

import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyFactor5X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={34}
      height={35}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 33.91c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16Z"
        fill="#EAB308"
      />
      <path d="M17 34.41c9.113 0 16.5-7.387 16.5-16.5S26.113 1.41 17 1.41.5 8.797.5 17.91s7.387 16.5 16.5 16.5Z" />
      <path
        d="m31.966 11.76-9.593-1.394-4.289-8.693a1.202 1.202 0 0 0-.547-.548 1.214 1.214 0 0 0-1.621.548l-4.289 8.693-9.593 1.395A1.206 1.206 0 0 0 1 12.972c.004.321.136.628.366.851l6.94 6.767-1.64 9.556a1.206 1.206 0 0 0 1.754 1.273L17 26.908l8.58 4.51a1.207 1.207 0 0 0 1.753-1.273l-1.64-9.555 6.942-6.767c.188-.185.313-.427.35-.69a1.205 1.205 0 0 0-1.02-1.372Z"
        fill="#fff"
        fillOpacity={0.5}
      />
      <path
        d="m12.487 18.054 1.664-2.56h2.464l-2.88 4.048c.64.8 1.222 1.579 1.744 2.336.523.757.94 1.435 1.248 2.032h-2.56c-.085-.16-.192-.357-.32-.592a11.183 11.183 0 0 0-.432-.752 12.3 12.3 0 0 0-.512-.784 8.38 8.38 0 0 0-.512-.72c-.16.203-.325.427-.496.672-.16.245-.314.496-.464.752l-.448.736c-.138.245-.26.475-.368.688H8.183c.128-.267.304-.581.528-.944a29.104 29.104 0 0 1 1.552-2.288c.288-.395.571-.763.848-1.104-.48-.672-.96-1.35-1.44-2.032-.48-.693-.954-1.376-1.424-2.048h2.576l1.664 2.56Zm10.156 2.592c0-.31-.07-.581-.208-.816-.139-.245-.379-.448-.72-.608-.341-.16-.8-.283-1.376-.368-.576-.085-1.301-.128-2.176-.128.117-.992.213-2 .288-3.024.075-1.024.133-1.984.176-2.88h6v2h-4c-.021.384-.048.757-.08 1.12-.021.352-.048.661-.08.928 1.557.107 2.704.475 3.44 1.104.747.619 1.12 1.483 1.12 2.592 0 .512-.09.987-.272 1.424a3.063 3.063 0 0 1-.816 1.136c-.363.32-.822.57-1.376.752-.544.181-1.184.272-1.92.272-.288 0-.592-.021-.912-.064a8.55 8.55 0 0 1-.912-.144 9.715 9.715 0 0 1-.784-.176 3.077 3.077 0 0 1-.544-.192l.432-1.968c.267.117.619.235 1.056.352.437.107.96.16 1.568.16.736 0 1.27-.144 1.6-.432.33-.299.496-.645.496-1.04Z"
        fill="#2D3344"
      />
    </svg>
  );
};
export default SvgVerifyFactor5X;
